<template>
    <div class="teacherList">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="teacherList_main">
            <div class="teacherList_main_header">
                <p class="teacherList_main_header_icon">
                    <img src="" alt="">
                    <!-- <span>老师</span> -->
                </p>
                <p class="teacherList_main_header_name">
                    <span :class="oneTypeId==item.id?'selectp':''" v-for="(item,index) in first_types_list" :key="index" @click="getOneType(item)">{{item.name}}</span>
                </p>
            </div>
            <div class="teacherList_main_list">
                <div class="teacherList_main_list_item" v-for="(item,index) in teacherList" :key="index">
                    <div class="teacherList_main_list_item_left">
                        <img :src="item.imagePath" alt="">
                    </div>
                    <div class="teacherList_main_list_item_right">
                        <div class="teacherList_main_list_item_right_name">{{item.teacherName}}</div>
                        <div class="teacherList_main_list_item_right_good">
                            <span v-for="(goodItem,key) in item.expertiseAreaNameList" :key="key" style="font-size:14px;">
                                <!-- {{goodItem}} -->
                                <el-tag type="info" style="margin-right:10px;font-size:12px;height:28px;line-height:26px;padding:0 5px;">{{ goodItem }}</el-tag>
                            </span>
                            
                        </div>
                        <div class="teacherList_main_list_item_right_synopsis long_text" v-html="item.introduction"></div>
                        <div class="teacherList_main_list_item_right_btn">
                            <div class="teacherList_main_list_item_right_btn_box" @click="goDetail(item)">
                                <span>进入主页</span>
                                <img src="" alt="">
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination v-if="teacherList.length >0" background layout="prev, pager, next" :total="count" @current-change="currentChange"></el-pagination>
            <div class="coursediv_empty" v-if="teacherList.length==0">
                <div class="coursediv_empty_img">
                    <img src="@/assets/class_empty.png" alt="">
                </div>
                <div class="coursediv_empty_text">无相关内容</div>
            </div>
        </div>
        <t_footer></t_footer>

    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { teacher_list,teacher_leibie } from "@/api/other";
import { first_types } from "@/api/home";
export default {
    name:'teacherList',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            isSort:false,
            teacherList:[],
            first_types_list:[],
            oneTypeId:'',
            isUserInfo:false,
            page:0,
            count:0
        }
    },
    mounted(){
        this.teacher_list()
        this.teacher_leibie()
    },
    methods:{
        // 点击一级类别
        getOneType(item){
            this.oneTypeId=item.id;
            this.teacher_list();
        },
        currentChange(page){
            this.page = page-1;
            this.teacher_list();
        },
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        // 去详情
        goDetail(item){
            let routeUrl =this.$router.resolve({
                path: '/teacherDetail',
                query: {
                    teacherObj:JSON.stringify(item)
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        // 课程所有一级分类
        teacher_leibie(){
            teacher_leibie().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    let params = {
                        name:'全部',
                        "id": "",
                    };
                    res.data.data.unshift(params);
                    this.first_types_list=res.data.data
                    // this.oneTypeId=res.data.data[0].id
                }else{
                    this.$message.error(res.data.msg);
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 名师列表
        teacher_list(){
            var obj={
                firstTypeId:this.oneTypeId,
                page:this.page
            }
            teacher_list(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.count = res.data.count;
                    this.teacherList=res.data.data;
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>
    .long_text{
        font-size:12px;
        display:-webkit-box;/**对象作为伸缩盒子模型展示**/
        -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
         overflow:hidden;/**隐藏超出的内容**/
         -webkit-line-clamp:6;/**显示的行数**/
    }
</style>